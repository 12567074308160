.woocommerce {

  select,
  .input-text {
    @extend .form-control;
    @extend .input-sm;
  }

  span.onsale {
    display: none;
  }

  a.remove {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 14px;
    color: $remove-btn-color !important;
    background-color: $remove-btn-background-color;

    &:hover {
      color: #FFFFFF;
      background-color: $brand-inverse-light !important;
    }
  }

  .woocommerce-breadcrumb {
    display: inline-block;
    float: right;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    text-align: right;
    color: #aaaaaa;
    margin: 0 0 10px 0;

    i {
      font-size: 18px;
      color: #cddce2;
      top: 2px;
    }

    a {
      color: $brand-primary;

      &:hover {
        color: #AAAAAA;
      }
    }
  }

  .woocommerce-loop-product__title,
  .vam-udesign-classic-loop-design__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .woocommerce-ordering,
  .woocommerce-products-per-page,
  .vam-udesign-classic-go-top {
    float: left;
    margin: 0 5px 1em 0;

    select {
      vertical-align: top;
      font-size: 14px;
      color: #FFFFFF;
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }

  nav.woocommerce-pagination {
    margin-bottom: 10px;

    &:nth-of-type(2) {
      display: none;
    }

    ul {
      border: 0;
      padding: 0;
      margin: 0;

      li {
        border: 0;
        padding: 0;

        a {
          @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-base, $line-height-small, $btn-border-radius-small);
          @include button-variant($btn-default-color, $btn-default-bg, #FFFFFF);
          min-width: 30px;
          margin-left: 5px;
        }

        .current {
          @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-base, $line-height-small, $btn-border-radius-small);
          @include button-variant(#FFFFFF, #CCCCCC, #FFFFFF);
          min-width: 30px;
          margin-left: 5px;
          pointer-events: none;
        }

        .dots {
          @include button-size($padding-small-vertical, 0, $font-size-base, $line-height-small, $btn-border-radius-small);
          margin-left: 5px;
          pointer-events: none;
        }
      }
    }
  }

  .product-box {
    @include clearfix;
    padding: 19px;
    margin-bottom: 20px;
    border-radius: $border-radius-small;
    box-shadow: 2px 2px 27px 0 rgba(50, 50, 50, .15);
  }

  .product-loop {
    &.design-loop.fluid-grid-list {
      margin: 0 auto 15px auto;
    }

    .product-loop-item {
      margin: 0 0 20px;

      .product-box {
        height: 100%;
        margin: 0;
        padding: 10px;
        overflow: hidden;

        &:hover {
          box-shadow: inset 0 0 0 2px $link-hover-color;

          .btn-quick-view {
            display: block;
          }
        }

        h3 {
          @include text-overflow;
          font-weight: 500;
          line-height: 1em;
          height: 1em;

          &:hover {
            color: $link-hover-color;
          }
        }

        .price {
          @include text-overflow;
          font-weight: 400;
          font-size: 14px;
          color: #aaaaaa;

          .amount {
            color: $brand-secondary;
          }
        }

        > a {
          display: block;
          height: 100%;
        }

        .product-box-thumb {
          padding: 0 0 65px;

          &.design-box-thumb {
            padding: 0 0 45px;
          }
        }

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        .product-box-info {
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 10px 0 0;
          border-top: 1px solid $hr-color;
        }

        .btn-quick-view {
          display: none;
          position: absolute;
          bottom: 100px;
          left: 50%;
          min-width: 140px;
          margin-left: -70px;
          box-shadow: 0 0 0 4px rgba($brand-primary,.5);
        }

        .add_to_cart_button {
          margin-top: 5px;
        }
      }
    }
  }

  .pswp {
    z-index: 999999;

    .pswp__ui {
      width: 100%;
      height: 100%;

      .pswp__caption__center {
        text-align: center;
      }
    }
  }

  div.product {
    clear: both;
    width: 100%;

    div.images {
      .woocommerce-main-image {
        display: block;
        width: 85%;
        padding-right: 5px;
      }

      .flex-control-thumbs {
        margin-top: 10px;
      }

      div.thumbnails {
        position: absolute;
        top: 0;
        right: 0;
        width: 15%;
        max-height: 100%;
        overflow: auto;
        padding-top: 0;

        a.zoom {
          display: block;
          width: 100% !important;
          margin: 0 !important;
        }

        img {
          margin-bottom: 5px;
          border: 2px solid $brand-primary;
          border-radius: $border-radius-small;
          background-color: #FFFFFF;
          cursor: pointer;
        }
      }
    }

    .product_title {
      padding: 0 0 10px 0;
      font: 22px/22px;
      font-weight: 400;
    }

    div.summary {
      padding: 20px;
      color: #FFFFFF;
      background-color: $brand-inverse;
      border-radius: $border-radius-small;

      .woocommerce-product-rating {
        margin-bottom: 15px;
      }

      div[itemprop=description] {
        margin-bottom: 15px;
      }

      .woocommerce-product-details__short-description {
        clear: both;

        p {
          margin-bottom: 15px;
        }
      }

      .woocommerce-result-count {
        margin: 0.5em 1em 0.5em 0;
      }

      .designer_product_cat_dropdown {
        margin: 0;

        .dropdown_product_cat {
          display: none;
        }

        .nav-tabs {
          border-color: transparent;

          > li {

            > a {
              &,
              &:hover,
              &:focus {
                margin-right: 5px;
                padding: 5px 15px;
                text-transform: uppercase;
                color: #FFFFFF;
                background-color: $brand-secondary;
                border-color: transparent;
                border-radius: $border-radius-small $border-radius-small 0 0;
              }
            }

            &.active {

              > a {
                color: $brand-secondary;
                background-color: #FFFFFF;
              }
            }
          }

          .dropdown-menu {
            border: 0;
            padding: 0;

            > li {

              > a {
                color: #FFFFFF;
                background-color: $brand-secondary;
              }

              &.active {

                > a {
                  &,
                  &:hover,
                  &:focus {
                    color: $brand-secondary;
                    background-color: #FFFFFF;
                  }
                }
              }
            }
          }
        }
      }

      .designer_products {
        margin: 0 0 2rem 0;
        padding: 10px;
        background-color: #ffffff;
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        max-height: 175px;

        .designer_product {
          width: 100%;

          .swatch-wrapper {
            width: 100%;
            border: 0;
            padding: 2px;
          }

          &.selected {
            .swatch-wrapper {
              border: 2px solid $brand-primary;
            }
          }
        }

        .owl-prev {
          left: -20px;
        }
        .owl-next {
          right: -20px;
        }
      }

      .single_add_to_cart_button {
        margin-right: 10px;
      }

      .design_variations_form {
        .single_variation {
          float: left;

          p.price,
          span.price,
          div.woocommerce-variation-price {
            margin-bottom: 0;
          }
        }

        .woocommerce-variation-add-to-cart {
          float: left;
        }

        .udesign-add-to-cart-button {
          clear: both;
          float: left;
          width: 50%;
          margin-top: 10px;

          button {
            width: 96%;
          }
        }

        .udesign-customize-button {
          float: right;
          width: 50%;
          margin-top: 10px;

          button {
            width: 96%;
            float: right;
          }
        }
      }

      .product_meta {
        float: right;
        margin-top: 10px;
      }

      p.price {
        float: left;
      }
    }

    p.price,
    span.price,
    div.woocommerce-variation-price {
      font-weight: 700;
      font-size: 24px;
      color: #FFFFFF;
      margin-bottom: 15px;
      margin-right: $padding-large-vertical;
    }

    form.cart {
      clear: both;
      margin-bottom: 0;

      .product-variations {

        .carousel-item {
          padding: 0 5px;
          cursor: pointer;
        }

        .variation-product {
          padding: 1px;
          border: 2px solid transparent;
          border-radius: $border-radius-small;

          &.active {
            border: 2px solid $brand-primary;
          }
        }
      }

      .attribute-variations {
        width: 100%;
        margin-bottom: 15px;
        padding: 18px 18px 8px;
        border: 2px solid $brand-inverse-light;
        border-radius: $border-radius-small;

        label {
          color: #FFFFFF;
        }
      }

      .variation-list {
        min-height: 28px;
        margin: 0 0 0 -1px;
        padding: 0;

        .variation {
          padding: 0;
          border: 0;
          border-radius: 0;
          margin-right: 5px;
          text-align: left;

          .swatch-wrapper {
            padding: 0;
            border: 0;
            border-radius: 0;
            background: $brand-inverse-light;
            margin-right: 5px;

            a {
              display: block;
              min-width: 32px !important;
              height: 32px !important;
              line-height: 32px !important;
              padding: 0;
              text-align: center;
              text-decoration: none;
              color: $brand-inverse;
              background-color: $brand-primary;
              border-radius: 0;
              box-shadow: inset 0 0 0 1px #D6DADF;

              &.pa_size {
                padding: 0px 12px;
              }
            }

            &.selected {
              a {
                &.pa_size {
                  color: #ffffff;
                  background-color: $brand-secondary;
                }
                &.pa_color {
                  text-shadow: initial;

                  &:after {
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }

      .button {
        @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-base, $line-height-large, $btn-border-radius-small);
      }

      [itemprop="offers"] {
        display: inline-block;
      }

    }
  }

  div.quantity {
    clear: both;
    margin: 0 10px 0 0;

    .quantity-control {
      float: left;
      display: inline-block;
      height: 38px;
      line-height: 38px;
      padding: 0 12px;
      border: 1px solid $brand-inverse;
      background-color: $brand-inverse-light;
      color: #ffffff;

      &.quantity-minus {
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
        cursor: pointer;
      }
      &.quantity-plus {
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
        cursor: pointer;
      }
      &:hover {
        color: $brand-primary;
      }
    }

    .input-text.qty {
      float: left;
      width: 3.631em;
      height: 38px;
      line-height: 38px;
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
      border-radius: 0;
      color: #ffffff;
      background-color: $brand-inverse-light;
      border: 1px solid $brand-inverse-light;
      border-top-color: $brand-inverse;
      border-bottom-color: $brand-inverse;
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.widget_shopping_cart,
  .widget_shopping_cart {
    color: #FFFFFF;

    .total {
      border-top: 1px solid $hr-color-bg;
      padding: 15px 20px 0;

      strong {
        font-weight: 400;
      }

      .amount {
        font-weight: 700;
      }
    }

    .buttons {
      padding: 0 20px;

      .button {
        border: 1px solid #FFFFFF;
        border-radius: $border-radius-small;
        min-width: 90px;
        width: 46%;
        margin: 0;

        &:last-of-type {
          float: right;
        }
      }
    }

    .woocommerce-mini-cart__empty-message {
      margin: 10px 0;
      text-align: center;
    }

    .cart_list {
      padding: 0;

      li {
        &.mini_cart_item {
          padding: 10px 15px 5px 15px;

          &:not(:first-child) {
            border-top: 1px solid $hr-color-bg;
          }

          .product-thumbnail {
            position: relative;
            min-height: 1px;
            float: left;
            padding: 0 10px;
            width: 90px;
          }

          .product-description {
            position: relative;
            min-height: 1px;
            float: left;
            padding: 0 10px 0 0;
            width: calc(100% - 90px);

            > a {
              display: block;
              max-width: 100%;
              @include text-overflow;
            }

            dl {
              margin: 0;

              dt {
                margin-top: 0;
                margin-bottom: 0;
              }

              dd {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          }

          .product-price {
            position: relative;
            min-height: 1px;
            float: left;
            padding: 8px 0;
            width: calc(100% - 90px);
          }

          .quantity {
            display: inline-block;
            padding: 5px 0;

            .amount {
              font-weight: 700;
            }
          }
        }

        a {
          display: inline-block;
          color: #FFFFFF;

          &.remove {
            top: 0;
            right: 10px;
            bottom: 0;
            left: auto;
            font-weight: 400;
            z-index: 1;
          }
        }

        img {
          float: left;
          width: 60px;
          margin: 0;
          border: 2px solid $brand-primary;
          border-radius: $border-radius-small;
          background-color: #FFFFFF;
        }

        dl {
          margin: 0;
          padding: 0;
          border: 0;

          dd,
          dt {
            width: auto;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            padding: 0 !important;
          }
        }
      }
    }
  }

  .cart-collaterals {
    .cart_totals {
      .shop_table {
        td {
          text-align: right;
        }
      }
    }
  }

  .woocommerce-order-details {
    .order_details {
      .product-name {
        clear: both;

        .order-item-thumbnails {
          float: left;
          margin-right: 15px;

          img {
            display: block;
            margin-bottom: 5px;
          }
        }
      }

      .product-total {
        vertical-align: top;
      }
    }
  }

  a.button,
  button.button,
  input.button,
  input.button:disabled,
  input.button:disabled[disabled],
  #respond input#submit {
    &,
    &.alt {
      outline: 0;
      @include button-variant($btn-primary-color, $btn-primary-bg, #FFFFFF);
      @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-base, $line-height-large, $btn-border-radius-small);
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
      min-height: 30px;
      touch-action: manipulation;
    }

    &:focus {
      outline: 0;
    }
  }

  table.shop_table {
    color: #FFFFFF;
    background-color: $brand-primary-dark;
    border-color: $hr-color-bg;

    thead,
    tfoot,
    td.actions {
      background-color: $brand-primary-darker;
    }

    td,
    tbody th,
    tfoot td,
    tfoot th {
      border-top-color: $hr-color-bg;
    }

    tr.cart_item {
      .input-text {
        color: #FFFFFF;
        background-color: $brand-inverse-light;
        border-color: $brand-inverse-light;
        padding-right: 2px;
      }

      td.product-name {
        a {
          font-weight: 500;
        }

        dl.variation {
          margin: 0;

          dd {
            margin-top: 0;
            margin-bottom: 0;
          }

          dt {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 400;
          }
        }
      }
    }

    td.actions {
      .coupon {
        input {
          line-height: $line-height-large;
        }

        .input-text {
          height: 38px;
          min-width: 150px;
          font-size: $font-size-base;
          border-radius: $btn-border-radius-small;
        }
      }
    }

    a {
      color: #FFFFFF;
    }

    .woocommerce-orders-table__cell-order-actions {
      .button {
        margin: 5px;
      }
    }

    dd,
    dt {
      width: auto;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      padding: 0 !important;
    }

    &.cart {
      .quantity-control {
        border: none;
        background-color: transparent;
        color: $quantity-control-color;

        &:hover {
          color: $quantity-control-color-hover;
        }
      }
    }
  }

  .info-stock {
    margin: 5px 0 0;

    li {
      height: 17px;
      line-height: 21px;
      margin: 0 15px 5px 0;
      font-size: 14px;
      color: #ffffff;
      float: left;

      &.stock {
        &.in-stock {
          > .fa {
            &::after {
              content: $fa-var-check;
            }
          }
        }

        &.available-on-backorder {
          > .fa {
            &::after {
              content: $fa-var-check;
            }
          }
        }

        &.out-of-stock {
          > .fa {
            &::after {
              content: $fa-var-close;
            }
          }
        }
      }
    }
  }

  .btn-size-guide {
    position: absolute;
    top: 12px;
    right: 7px;
    color: #FFFFFF;
    z-index: 1000;

    &:hover {
      color: $brand-primary;
    }
  }

  .woocommerce-info {
    border-top-color: $brand-primary;
    background-color: $sidebar-background;

    &:before {
      color: $brand-primary;
    }
  }

  .woocommerce-shipping-fields{
    #ship-to-different-address {
      .woocommerce-form__label-for-checkbox {
        margin: 0 20px;

        #ship-to-different-address-checkbox {
          vertical-align: middle;
          margin-top: 0px;
        }
      }
    }
  }

  .woocommerce-order-details {
     header {
       h2:last-child {
        margin-bottom: 10px;
      }
    }
  }
}

/**
 * Cart page
 */
.post-type-archive-design {
  .designs-archive-search {
    width: 100%;
    margin-bottom: 20px;
    margin: 10px 0 40px 0;

    .designs-archive-search-form {
      .search-form {
        #vam-udesign-design-search-field {
          min-height: 45px;
          font-size: 16px;
        }

        .search-btn {
          top: 0px;
          font-size: 18px;
          height: 100%;
          background: $btn-default-bg;
          color: $btn-default-color;
          border-top-right-radius: $border-radius-small;
          border-bottom-right-radius: $border-radius-small;

          &:hover {
            background: $brand-secondary;
          }
        }
      }
    }
  }
}

/**
 * Cart page
 */
.woocommerce-cart {
  table.cart {
    td.product-thumbnail {
      padding-left: 0;
      padding-right: 0;
    }

    td.product-name {
      a {
        font-weight: 700;
      }

      dl.variation {
        margin: 0;

        dt {
          font-weight: 400;
        }
      }
    }

    img {
      width: 70px;
      border: 2px solid $hr-color-bg;
      border-radius: $border-radius-small;
      background: #ffffff;
    }

    th,
    td {

    }

    td.actions {
      .coupon {
        .input-text {

        }
      }
    }

    input {

    }
  }

  .wc-proceed-to-checkout {
    a.checkout-button {

    }
  }

  .cart-collaterals {
    .shipping_calculator {
      .button {

      }

      .shipping-calculator-button {
        &:after {

        }
      }
    }

    .cart_totals {
      p {
        small {

        }
      }

      table {

        tr:first-child {
          th,
          td {

          }
        }

        th {

        }

        td,
        th {

        }

        small {
          color: #FFFFFF;
        }

        select {

        }
      }

      .discount td {

      }

      tr td,
      tr th {
        border-top-color: $hr-color-bg;
      }
    }

    .cross-sells {
      ul.products {
        li.product {

        }
      }
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .woocommerce {
    nav.woocommerce-pagination {
      float: right;

      &:nth-of-type(2) {
        display: block;
      }
    }
  }
}

@media (max-width: $grid-float-breakpoint) {
  .woocommerce {
    .woocommerce-products-per-page,
    .woocommerce-ordering {
      display: block;
      width: 100%;
      float: none;
    }
  }
}

/**
 * Checkout page
 */
.woocommerce-checkout {
  #customer_details {
    padding-bottom: 20px;
  }

  .woocommerce-checkout-review-order {
    padding-bottom: 20px;

    #payment {
      .payment_method_paypal {
        .about_paypal {
          line-height: 32px;
          margin-left: 10px;
        }
      }
    }
  }

  #payment {
    .wc-terms-and-conditions {
      padding: 5px;

      label.woocommerce-form__label-for-checkbox {
        margin-left: 20px;

        #terms {
          margin: 6px 0 0 -20px;
        }
      }
    }
  }
}

/**
 * Small screen shop_table_responsive override
 */
@media (max-width: $screen-sm-min) {
  html {
    .woocommerce,
    .woocommerce-page {

      table.shop_table_responsive {
        thead {
          display: table-header-group;
        }

        tbody {
          tr:first-child {
            td:first-child {
              border-top-width: 1px;
            }
          }

          th {
            display: table-cell;
          }
        }

        tr {
          display: table-row;

          td {
            display: table-cell;
            text-align: left !important;

            &.order-actions {
              text-align: right !important;
            }

            &:before {
              display: none;
            }

            &.product-remove,
            &.actions {
              &:before {
                display: none;
              }
            }
          }

          &:nth-child(2n) {
            td {
              background-color: inherit;
            }
          }
        }
      }
    }
  }
}

.my-account:not(.logged-in) {
  .woocommerce  {
    .login,
    .register {
      border: none;
      box-shadow: 2px 2px 27px 0 rgba(50, 50, 50, 0.15);
      margin-top: 15px;
    }

    .register {
      padding-bottom: 30px;
    }

    .login {
      padding-bottom: 10px;
      .lost_password {
        margin-bottom: 0;
      }
    }

    .lost_reset_password {
      padding: 20px;
      box-shadow: 2px 2px 27px 0 rgba(50, 50, 50, 0.15);
      margin-bottom: 20px;
    }

    h2 {
      padding-top: 10px;
    }
  }
}

.udesign-flat {
  .woocommerce {
    table.shop_table {
      color: $main-text-color;
      background-color: transparent;
      border-color: transparent;
      border-radius: 0;
      border: none;

      a.remove {
        background-color: transparent;
      }

      .quantity-control {
        color: $quantity-control-color-flat;

        &:hover {
          color: $quantity-control-color-flat-hover;
        }
      }

      &.cart {
        .quantity {
          input {
            background: transparent;
            border: 1px solid $hr-color;
            color: #555;
            font-size: $font-size-h3;
          }
        }
      }

      thead,
      tfoot,
      td.actions {
        background-color: transparent;
      }

      td,
      tbody th,
      tfoot td,
      tfoot th {
        border-top-color: $hr-color;
      }

      th {
        text-transform: uppercase;
      }

      a {
        color: $table-link-color;

        &:not(.remove):not(.button){
          &:hover {
            color: $table-link-hover-color;
          }
        }
      }

      a.button,
      button.button,
      button.button:disabled,
      input[type="button"],
      input[type="submit"]{
        color: $btn-default-color;
      }

      tr {
        th, td {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }

      img {
        border: none;
        border-radius: 0;
        background: transparent;
      }
    }

    .cart-collaterals {
      .cart_totals {
          tr td,
          tr th {
            border-top-color: $hr-color;
          }
      }
    }
  }

  .woocommerce-cart {
    .woocommerce-cart-form {
      margin-top: -14px;
    }
  }
}
