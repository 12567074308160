.designer-subscription-plans {
  background: #ffffff;
  box-shadow: 2px 2px 27px 0 rgba(50,50,50,.2);
  margin: 10px 10px 20px 10px;
  border-radius: 3px;
  padding: 20px 25px;

  .perks {
    list-style: none;
    margin-top: 20px;
    padding: 0;
    font-size: 18px;

    .perk {
      margin: 15px 0;

      .title {
        i {
          color: #429F31;
        }
      }

      .description {
        color: #999;
        font-size: 16px;
        margin-left: 32px;
        text-align: justify;
      }
    }
  }
}

