.udesign-designer-page {
  .udesign {
    .designer-wrapper {
      .designer-header {
        .designer-download-menu {
          width: 250px;
          margin-left: 10px;
        }

        .designer-download-popup {
          &:before {
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            margin-top: -4.5px;
            top: -3px;
            right: 110px;
            bottom: auto;
            left: auto;
            border-style: solid;
            border-width: 0 15px 15px;
            border-color: white transparent;
            content: "";
          }

          width: 250px;
          display: none;
          position: absolute;
          padding: 15px 12px;
          box-sizing: border-box;
          background: #ffffff;
          border-radius: 3px;
          z-index: 10;
          box-shadow: 2px 2px 27px 0px rgba(50, 50, 50, 0.2);
          margin: 5px 0 0 10px;

          &.open {
            display: block;
          }

          .designer-download-popup-title {
            text-align: center;
            font-size: 16px;
          }

          .designer-download-popup-terms {
            margin: 20px 0 10px 0;
            text-align: center;
            width: 100%;
            font-weight: 700;
            font-size: 15px;

            input {
              margin: -2px 0 0;
              line-height: normal;
              vertical-align: middle;
              height: 15px;
              width: 15px;
            }

            .btn-terms-conditions {
              font-weight: 700;
              cursor: pointer;
              touch-action: manipulation;
            }
          }

          .designer-download-btn {
            display: block;
            box-sizing: border-box;
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }

    .udesign-designer-messages {
      .designer-msg {
        font-size: 17px;
        color: #ffffff;
        padding: 8px;
        margin: 0 10px 10px 10px;
        border-radius: 5px;
        text-align: center;
        text-transform: uppercase;

        &.msg-error {
          background-color: #f44336;
        }

        &.msg-warning {
          background-color: #ff9800;
        }
      }
    }
  }
}
